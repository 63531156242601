<!--
 * @Author: your name
 * @Date: 2020-12-14 16:56:48
 * @LastEditTime: 2022-11-01 16:35:21
 * @LastEditors: Please set LastEditors
 * @Description: 新增信息价
 * @FilePath: \BusinessPlatform\src\views\salesBusiness\marketInforPrice\addPrice.vue
-->
<template>
    <div class="marketInforPrice-addPrice">
        <el-form
            class="el_form_newStyle"
            :label-position="labelPosition"
            label-width="1.2rem"
            :model="form"
        >
            <el-form-item label="信息发布地区:">
                <el-input
                    v-model="form.region_name"
                    placeholder="请输入内容"
                    :disabled="isDisabled"
                ></el-input>
            </el-form-item>
            <el-form-item label="行政区划代码:">
                <el-input
                    v-model="form.region_code"
                    placeholder="请输入内容"
                    :disabled="isDisabled"
                ></el-input>
            </el-form-item>
            <el-form-item label="模式:">
                <el-radio-group v-model="form.price_model">
                    <el-radio :label="1">
                        单一价
                    </el-radio>
                    <el-radio :label="2">
                        区间价
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="区间价取值:" v-if="form.price_model == 2">
                <el-radio-group v-model="form.interval_price_type">
                    <template v-for="(item, index) in intervalPriceTypeData">
                        <el-radio :key="index" :label="item.dic_code">
                            {{ item.dic_name }}
                        </el-radio>
                    </template>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="更新频率:">
                <el-select
                    v-model="form.update_frequency_code"
                    placeholder="请选择更新频率"
                    @change="selectUpdateFrequency"
                >
                    <template v-for="(item, index) in updateFrequencyCodeData">
                        <el-option
                            :label="item.dic_name"
                            :value="item.dic_code"
                            :key="index"
                        ></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item label="更新日期:">
                <el-input-number
                    v-model="form.update_date"
                    :min="1"
                    :max="31"
                    :controls="false"
                    label="更新日期"
                ></el-input-number>
                <span style="margin-left:0.14rem;">日</span>
            </el-form-item>
            <el-form-item label="登记时间:">
                <el-date-picker
                    v-model="form.register_date"
                    type="date"
                    placeholder="选择登记时间"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="登记人:">
                <el-input
                    v-model="form.register_user"
                    placeholder="请输入内容"
                    disabled
                ></el-input>
            </el-form-item>
            <el-form-item label="是否启用:">
                <el-radio-group v-model="form.is_enable">
                    <el-radio :label="1">
                        启用
                    </el-radio>
                    <el-radio :label="2">
                        停用
                    </el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div class="btn-con">
            <el-button type="primary" @click="save">
                保存
            </el-button>
            <el-button @click="closeBoxWindow">
                关闭
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            labelPosition: 'right',
            // 区间价待选数据
            intervalPriceTypeData: [],
            // 更新频率待选数据
            updateFrequencyCodeData: [],
            // 表单保存数据
            form: {
                region_name: '',
                region_code: '',
                price_model: null,
                update_frequency: '',
                update_frequency_code: '',
                update_date: null,
                register_date: '',
                register_user: '',
                interval_price_type: null,
                is_enable: 1,
            },
            // 编辑，只读
            isDisabled: false,
        };
    },
    watch: {},
    computed: {},
    created() {
        // this.extr.row 编辑
        if (this.extr && this.extr.row) {
            this.isDisabled = true;
            this.initData();
        }
        // 当前登录人赋值
        this.form.register_user = this.$takeTokenParameters('Name');
        // 信息价区域区间价类型
        this.getDictionary('interval_price_type');
        // 信息价更新频率
        this.getDictionary('update_frequency');
    },
    mounted() {},
    methods: {
        // 初始化数据
        initData() {
            this.$axios
                .get(`/interfaceApi/sale/info_price/get_regional_by_id?id=${this.extr.row.id}`)
                .then(res => {
                    if (res) {
                        this.form = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        closeBoxWindow() {
            this.$parent.hide();
        },
        // 选择更新频率
        selectUpdateFrequency() {
            const row = this.updateFrequencyCodeData.filter(item => item.dic_code === this.form.update_frequency_code);
            this.form.update_frequency = row[0].dic_name;
        },
        // 保存
        save() {
            if (this.isDisabled) {
                this.modificationRequest();
            } else {
                this.saveRequest();
            }
        },
        // 保存请求
        saveRequest() {
            this.$axios
                .post('/interfaceApi/sale/info_price/add_regional', this.form)
                .then(() => {
                    this.$message({
                        message: '添加成功！',
                        type: 'success',
                    });
                    this.$parent.hide();
                    this.extr.table.getTableList();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 修改请求
        modificationRequest() {
            this.$axios
                .put('/interfaceApi/sale/info_price/edit_regional', this.form)
                .then(() => {
                    this.$message({
                        message: '修改成功！',
                        type: 'success',
                    });
                    this.$parent.hide();
                    this.extr.table.getTableList();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 拉取选项数据
        getDictionary(code) {
            this.$axios
                .get(`/interfaceApi/sale/contract_manager/get_dictionary?groupCode=${code}`)
                .then(res => {
                    if (code === 'interval_price_type') {
                        this.intervalPriceTypeData = res;
                        this.intervalPriceTypeData.forEach(item => {
                            item.dic_code = Number(item.dic_code);
                        });
                    } else if (code === 'update_frequency') {
                        this.updateFrequencyCodeData = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
};
</script>
<style lang="stylus">
.marketInforPrice-addPrice
    height 100%
    padding .2rem
    position relative
    .el-select
        width 100%
    .el-date-editor
        width 100% !important
    .sys-code
        margin-left 1.2rem
        label
            width .8rem
            display inline-block
        .el-input
            width calc(100% - .8rem)
        .sys-row
            margin-bottom .1rem
            label
                font-size .14rem
    .el-form-item
        margin-bottom .1rem
    .btn-con
        text-align center
        position absolute
        bottom .2rem
        width calc(100% - 0.4rem)
        .el-button
            width 1.3rem
            height 0.36rem
            line-height 0.34rem
            padding 0
            margin 0 0.3rem
</style>
